<template>
	<div>
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%">
					<a-row type="flex" justify="space-between">
						<a-col span="12">
							<common-page-btn @category="$refs.category._show()" @addgoods="$refs.addForm._show()" @inventory="visibleCon">
								<template slot="mySlot">
									<a-button  v-has="'Integral:goods:inventory'" @click="$refs.ExcelFormRef._show()">报表导出</a-button>
								</template>
							</common-page-btn>
						</a-col>
						<a-col span="12">
							<a-row type="flex" align="middle" justify="end" :gutter="3">
								<a-col span="12">
									<a-input v-model="param.keyword" allowClear search @pressEnter="getList()" @change="change" @on-clear="getList()" placeholder="关键词查询"></a-input>
								</a-col>
								<a-col span="6" v-has="'study:category'">
									<a-select
										v-model="param.categoryId"
										style="width: 100%"
										@focus="
											e => {
												this.getCategoryList();
											}
										"
										@change="
											e => {
												this.getList();
											}
										"
										placeholder="根据类别进行筛选"
									>
										<a-select-option value="">所有</a-select-option>
										<a-select-option v-for="category in categoryList" :key="category.categoryId" :value="category.categoryId">{{ category.categoryName }}</a-select-option>
									</a-select>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
				</a-affix>
			</div>
		</a-layout>
		<!-- 数据表 -->
		<a-table :columns="columns" :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false" :indentSize="25" :style="{ background: '#FFF' }" size="small" row-key="productId" border :loading="loading" :data-source="data.records">
			<!-- 标题 -->
			<template slot-scope="row" slot="rowtitle">
				<a @click="$refs.detail.show(row)">{{ row.productName }}</a>
			</template>
			<!-- 图片 -->
			<template slot-scope="row" slot="productImage">
				<img style="width: 80px; height: 60px" :src="row.productImage" alt="" />
			</template>
			<!-- 上架 -->
			<template slot-scope="row" slot="rowgrounding">
				<a-switch v-has="'integral:grounding'" @change="changeSwitch($event, row)" checked-children="上架" un-checked-children="下架" :default-checked="row.status == 1 ? true : false" />
			</template>

			<!--操作-->
			<template slot-scope="row" slot="action">
				<a-button-group shape="circle" size="small">
					<a-tooltip title="修改数据"><a-button v-has="'integral:addgoods'" type="link" icon="edit" @click="$refs.addForm._show(row)"></a-button></a-tooltip>
					<a-tooltip title="删除数据"><a-button v-has="'integral:delgoods'" type="link" icon="delete" @click="del(row.productId)"></a-button></a-tooltip>
				</a-button-group>
			</template>
		</a-table>
		<a-row>
			<a-col span="12" offset="6" style="text-align: center; padding: 20px 0">
				<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext" @showSizeChange="setLimit" :current="data.current" />
			</a-col>
		</a-row>
		<a-modal title="库管专用项" :visible="visible" @ok="handleOk" @cancel="visible = false" centered destroyOnClose>
			<a-form-model :rules="formRule" :model="formData" ref="formRef">
				<a-row :gutter="15">
					<a-col span="12">
						<a-form-model-item label="选择物品" prop="productId">
							<selectGoods @confirm="confirmGoods"></selectGoods>
						</a-form-model-item>
					</a-col>
					<a-col span="12">
						<a-form-model-item label="选择类型" prop="type">
							<a-select v-model="formData.type">
								<a-select-option value="true">入库</a-select-option>
								<a-select-option value="false">出库</a-select-option>
							</a-select>
						</a-form-model-item>
					</a-col>
				</a-row>
				<a-row :gutter="15">
					<a-col span="12">
						<a-form-model-item label="输入物品数量" prop="number">
							<a-input-number style="width: 100%" :min="1" v-model="formData.number" placeholder="请输入物品数量"></a-input-number>
						</a-form-model-item>
					</a-col>
					<a-col span="12" v-if="formData.type == 'true'">
						<a-form-model-item label="输入物品价格" prop="price">
							<a-input-number style="width: 100%" step="1.00" :precision="2" :min="1" v-model="formData.price" placeholder="请输入入库价格"></a-input-number>
						</a-form-model-item>
					</a-col>
					<a-col span="24">
						<a-form-model-item label="输入备注" prop="remark">
							<a-textarea allow-clear v-model="formData.remark" placeholder="请输入备注"></a-textarea>
						</a-form-model-item>
					</a-col>
				</a-row>
			</a-form-model>
		</a-modal>
		<add-form ref="addForm" @success="doRefresh"></add-form>

		<detail ref="detail"></detail>
		<category ref="category"></category>
    <ExcelForm ref="ExcelFormRef"></ExcelForm>
	</div>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import utils from '@/common/utils';
import addForm from './components/addForm.vue';
import category from './components/category.vue';
import detail from './components/detail.vue';
import selectGoods from './components/selectGoods.vue';
import ExcelForm from './components/ExcelForm.vue';
export default {
	components: { addForm, category, detail, selectGoods,ExcelForm },
	data() {
		return {
			data: {},
			loading: false,
			visible: false,
			organList: apiUtil.getOrganList(),
			pageSize: 10,
			categoryList: [],
			total: '',
			formData: {
				productId: '',
				type: 'true',
				number: 1,
				price: 1,
				remark: ''
			},
			param: {
				current: 1,
				limit: 20,
				keyword: '',
				categoryId: '',
				organId: apiUtil.getOrganId()
			},
			columns: [
				{ title: '物品名称', scopedSlots: { customRender: 'rowtitle' }, width: 100 },
				{ title: '图片', scopedSlots: { customRender: 'productImage' }, width: 160, resizable: true, align: 'center' },
				{ title: '兑换所需积分', dataIndex: 'pointsRequired', width: 160, resizable: true, align: 'center' },
				{ title: '物品数量', dataIndex: 'stockQuantity', width: 160, resizable: true, align: 'center' },
				{ title: '上架/下架', scopedSlots: { customRender: 'rowgrounding' }, align: 'center', width: 100 },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 100 }
			],
			formRule: {
				productId: [{ required: true, message: '请选择物品', trigger: 'change' }],
				type: [{ required: true, message: '请选择类型', trigger: 'change' }],
				price: [{ required: true, message: '请输入物品价格', trigger: 'change' }],
				number: [{ required: true, message: '请输入物品数量', trigger: 'change' }],
				remark: [{ required: true, message: '请输入备注', trigger: 'change' }]
			}
		};
	},
	mounted() {
		this.getList();
	},
	methods: {
		...apiUtil,
    confirmGoods(e,a) {
			this.formData.productId = e[0].productId;
			// this.formData.productName = e[0].productName;
			// this.formData.pointsRequired = e[0].pointsRequired;
		},
		// 打开库存管理
		visibleCon() {
			this.visible = true;
			this.formData.productId = '';
			this.formData.type = 'true';
			this.formData.number = 1;
			this.formData.price = 1;
			this.formData.remark = '';
		},
		// 库存登记
		handleOk() {
			this.$refs.formRef.validate().then(valid => {
				if (valid) {
					console.log('233');
					console.log(this.formData);
					if (this.formData.type == 'false') {
						this.formData.price = '';
					}
					 this.http.post('/platform/reward/store/goodsInventory/save',this.formData).then(ret=>{
					   if(ret.code == 200){
					     utils.success('操作成功！').then(() => {

					      this.visible=false;
						  this.doRefresh()
					     });
					   }
					 })
				}
			});
		},
		// 改变上架或者下架状态
		changeSwitch(e, row) {
			console.log(e, row);
			if (e) {
				row.status = 1;
			} else {
				row.status = 0;
			}
			utils.showSpin();
			this.http.post('/platform/reward/store/add', row).then(ret => {
				if (ret.code == 200) {
					utils.success('操作成功！').then(() => {});
				}
			});
		},
	
		// 获取数据
		getList() {
			this.loading = true;
			this.http
				.get('/platform/reward/store/list', this.param)
				.then(ret => {
					this.data = ret.data;
					this.total = data.total;
					this.param.current = data.current;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},
		change(e) {
			if (e.target.value == '') {
				this.getList();
			}
		},
		// 删除
		del(id) {
			this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
				utils.showSpin();
				this.http.delete('/platform/reward/store/delete/' + id).then(ret => {
					console.log(ret);
					if (ret.code == 200) {
						this.utils.success('操作成功！').then(() => {
							this.getList();
							// console.log(1111);
						});
					}
				});
			});
		},
		// 筛选条件变化重新获取数据
		doRefresh() {
			this.param.current = 1;
			this.getList();
		},

		// 筛选单位
		filterOrgan(e) {
			this.param.organId = e;
		},
		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getList();
		},
		// 获取分类数据
		getCategoryList() {
			this.loading = true;
			this.http
				.get('/platform/reward/store/get/category')
				.then(ret => {
					if (ret.code == 200) {
						this.loading = false;
						this.categoryList = ret.data;
					}
				})
				.catch(err => {
					this.loading = false;
					console.log(err);
				});
		}
	}
};
</script>

<style></style>
