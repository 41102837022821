<template>
  <a-modal v-model="visible" :title="false" width="50%" :footer="null" @cancel="_close" centered destroyOnClose>
    <a-page-header bordered :ghost="false" :title="`物品名称：${data.productName}`">

      <div style="max-height: 300px;overflow-y: scroll;" v-html="data.content"></div>
    </a-page-header>

  </a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
export default {
  data() {
    return {
      visible: false,
      loading: false,
      data: {
        title: '',
        content: ''
      }
    };
  },
  methods: {
    ...apiUtil,
    show(row) {
      console.log(row);
      this.visible = true;
      this.data = row;
    },
    _close() {
      this.visible = false;
    },

  }
};
</script>
<style>
.content img {
  max-width: 100% !important;
}

.countdown {
  width: 30px;
  height: 30px;
  border: 1px solid blue;
  border-radius: 50%;
  text-align: center;
}

.time {
  line-height: 30px;
}
</style>
