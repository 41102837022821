<template>
  <a-modal v-model="show" :title="formData.productId ? '修改物品信息' : '添加物品信息'" :width="700" @ok="doSubmit" @cancel="_close"
           centered destroyOnClose>
    <a-form-model ref="formRef" :model="formData" :rules="formRule">
      <a-row :gutter="15">
        <a-col span="8">
          <a-form-model-item label="所属类别" label-position="top">
            <a-select v-model="formData.categoryId" placeholder="请选择所属类别">
              <a-select-option v-for="category in categoryList" :key="category.categoryId" :value="category.categoryId">{{
                category.categoryName
              }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col span="8">
          <a-form-model-item label="物品名称" label-position="top">
            <a-input v-model="formData.productName" placeholder="请输入物品名称"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col span="8">
          <a-form-model-item label="物品是否上架" label-position="top">
            <a-switch v-model="status" checked-children="上架" un-checked-children="下架" default-checked />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="15">
        <a-col span="4" v-if="formData.productId==''">
          <a-form-model-item label="物品的初始库存" label-position="top">
            <a-input v-model="formData.stockQuantity" placeholder="请输入物品的数量"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col span="4" v-if="formData.productId==''">
          <a-form-model-item label="物品的价格" label-position="top">
            <a-input-number v-model="formData.price" placeholder="请输入物品的价格"></a-input-number>
          </a-form-model-item>
        </a-col>
        <a-col span="6">
          <a-form-model-item label="兑换物品所需的积分" label-position="top">
            <a-input v-model="formData.pointsRequired" placeholder="请输入兑换物品所需的积分"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col span="10">
          <a-form-model-item label="描述" label-position="top">
            <a-input v-model="formData.productDescription" placeholder="请输入描述"></a-input>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="15">
        <a-col span="6">
          <a-form-model-item label="上传物品图片" label-position="top">
            <upImg @success="e => formData.productImage = e" :thumb="formData.productImage"></upImg>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="15">
        <a-form-model-item label="物品详情" label-position="top">
          <editor v-model="formData.content" />
        </a-form-model-item>
      </a-row>
    </a-form-model>
  </a-modal>
</template>

<script>
import request from '@/config/request';
import utils from '@/common/utils';
import apiUtil from '@/common/apiUtil';
import upImg from '@/components/common/upImg.vue';
import editor from '@/components/component/editor.vue';
export default {
  components: { upImg, editor },
  data() {
    return {
      show: false,
      status: true,
      loading: false,
      categoryList: [],
      formData: {
        productId: '',
        productImage: '',
        categoryId: '',
        productName: '',
        productDescription: '',
        stockQuantity: 0,
        pointsRequired: 0,
        status: 0,
        price:0,
        organId: apiUtil.getOrganId(),
        content: ''
      },
      formRule: {}
    };
  },
  methods: {
    ...apiUtil,
    _show(data) {
      this.show = true;
      this.getCategoryList();
      this.$nextTick(() => {
        if (data) {
          // console.log(id);
          // this.formData.id = 3;
          console.log(data);
          this.formData = data;
          if (data.status == 1) {
            this.status = true;
          } else {
            this.status = false;
          }
        } else {
          this.formData.productId = '';
          this.formData.categoryId = '';
          this.formData.productName = '';
          this.formData.productDescription = '';
          this.formData.stockQuantity = 0;
          this.formData.pointsRequired = 0;
          this.formData.status = 0;
          this.formData.content = '';
          this.formData.productImage=''

        }
      });
    },
    _close() {
      this.$refs.formRef.resetFields();
      this.show = false;
      this.$emit('success');
    },
    doSubmit() {
      if (this.status) {
        this.formData.status = 1;
      } else {
        this.formData.status = 0;
      }
      this.$refs.formRef.validate(valid => {
        if (valid) {
          // console.log(this.formData.managerDepIds); return;
          utils.showSpin();
          request.post('/platform/reward/store/add', this.formData).then(ret => {
            this.$refs.formRef.resetFields();
            this.show = false;
            utils.success('操作成功！').then(() => {
              this.show = false;
              this.$emit('success');
            });

          });
        }
      });
    },
    // 获取分类数据
    getCategoryList() {
      this.loading = true;
      this.http
        .get('/platform/reward/store/get/category')
        .then(ret => {
          if (ret.code == 200) {
            this.categoryList = ret.data;
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}
</style>
